import React from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loader from "react-loader-spinner";
import { FormattedMessage, useIntl } from "react-intl";
import * as Dompurify from "dompurify";

const TermsForm = (props) => {
  const { onSubmit } = props;
  const {
    isLoading,
    config: { agreementText },
  } = useSelector((state) => state.config);

  const cleanDom = Dompurify.sanitize(agreementText, {
    USE_PROFILES: { html: true },
  });

  const intl = useIntl();

  const errorAcceptTnCRequired = intl.formatMessage({
    id: "terms.form.formik.error.terms.required",
    defaultMessage: "Accept Terms & Conditions is required",
    description:
      "Error message of not ticking checkbox Accept Terms & Conditions",
  });

  const formik = useFormik({
    initialValues: { acceptTerms: false },
    validationSchema: Yup.object({
      acceptTerms: Yup.bool().oneOf([true], errorAcceptTnCRequired),
    }),
    onSubmit: (values) => {
      onSubmit();
    },
  });

  return (
    <main className="app__main">
      {isLoading && (
        <div className="spinner-wrap">
          <Loader type="Puff" color="#f25f4c" />
        </div>
      )}
      <form className="form form--boxed" onSubmit={formik.handleSubmit}>
        <div className="form__field margin-bottom-24">
          <p className="field__heading">
            <FormattedMessage
              id="terms.form.title.terms.and.condition"
              defaultMessage="Legal Terms & Conditions"
              description="Title of term and condition section"
            />
          </p>
          <div
            className="field__terms"
            dangerouslySetInnerHTML={{ __html: cleanDom }}
          />
        </div>
        <div className="form__field margin-bottom-24">
          <input
            name="acceptTerms"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.acceptTerms}
            className="field__check-control"
            type="checkbox"
            id="checkbox-1"
          />
          <label className="field__check-label" htmlFor="checkbox-1">
            <FormattedMessage
              id="term.form.checkbox.agreement"
              defaultMessage="I agree with the Terms & Conditions"
              description="Checkbox label of agreeing with terms and condition"
            />
          </label>
        </div>
        <div className="form__button-group">
          <input
            type="button"
            className="button button--outline"
            value={intl.formatMessage({
              id: "terms.form.button.back",
              defaultMessage: "Back",
              description: "Action button to go to previous page",
            })}
            onClick={props.onClickBack}
          />
          <input
            type="submit"
            className="button button--primary"
            value={intl.formatMessage({
              id: "term.form.button.submit",
              defaultMessage: "Submit",
              description: "Action button to submit the form",
            })}
            disabled={!formik.values.acceptTerms}
          />
        </div>
      </form>
    </main>
  );
};

export default React.memo(TermsForm);
