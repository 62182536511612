import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import React, { useCallback, useEffect, useState, useContext } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getConfig, getInvitation, register } from "store/configSlice";
import { isEmpty, isNil } from "lodash-es";
import RegistrationForm from "../containers/RegistrationForm";
import TermsForm from "../containers/TermsForm";
import { LanguageContext } from "../lang/LanguageContextProvider";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function languageQuerytoLocale(language) {
  switch (language) {
    case "English":
      return "en-gb"; // need to change to en-gb instead of en to follow dd-mm-yyyy format
    case "Japanese":
      return "ja";
    case "Korean":
      return "ko";
    case "Chinese":
      return "zh";
    default:
      return "en-gb";
  }
}

const RegistrationScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { changeLanguageFromLocale } = useContext(LanguageContext);

  const { config, error, registrationId } = useSelector(
    (state) => state.config
  );

  const query = useQuery();
  const qr = query.get("qr");
  const language = query.get("language");

  const locale = languageQuerytoLocale(language);

  changeLanguageFromLocale(locale);

  const initialValues = {
    name: "",
    organization: "",
    email: "",
    phone: "",
    visitorType: "VISITOR",
    visitorForms: [{}],
    acceptTerms: [],
  };

  const [values, setValues] = useState(initialValues);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isEmpty(qr)) {
      history.push("/registration-error");
    }
    dispatch(getConfig(qr)).then((result) => {
      if (!isNil(result.error)) {
        history.push("/registration-error");
      }
      setIsLoading(false);
    });

    return () => setIsLoading(false);
  }, [dispatch, qr, history]);

  useEffect(() => {
    if (!isNil(registrationId)) {
      setIsLoading(true);
      dispatch(getInvitation(registrationId)).then((result) => {
        if (isNil(result.error)) {
          const newValues = {
            name: result.payload.name,
            organization: result.payload.organization,
            email: result.payload.email,
            phone: result.payload.phone,
            visitorType: result.payload.visitorType,
            acceptTerms: [],
          };

          setValues(newValues);
        }
        setIsLoading(false);
      });
    }
  }, [dispatch, registrationId]);

  const handleSubmit = useCallback(() => {
    if (!isNil(registrationId)) {
      values.registrationId = registrationId;
    }
    if (values.visitorType === "DELIVERY") {
      delete values.email;
    }
    dispatch(register(values)).then((a) => {
      setStep(2);
      setIsSubmited(false);
    });
  }, [dispatch, values, registrationId]);

  const handleNext = useCallback(
    (data) => {
      setValues(data);
      if (config.enableSignature) {
        if (data.visitorType === "DELIVERY") {
          setIsSubmited(true);
        } else {
          setStep(1);
        }
      } else {
        setIsSubmited(true);
      }
    },
    [config]
  );

  useEffect(() => {
    if (isSubmited) {
      handleSubmit();
    }
  }, [handleSubmit, isSubmited, values]);

  /*
	Step
	0: registration form
	1: terms page
	2: submit form, go another page
	*/
  const [step, setStep] = useState(0);

  const renderContent = () => {
    switch (step) {
      case 2:
        return <Redirect to="/registration-completed" />;
      case 1:
        return (
          <TermsForm onSubmit={handleSubmit} onClickBack={() => setStep(0)} />
        );
      default:
        return <RegistrationForm values={values} onSubmit={handleNext} />;
    }
  };

  return (
    <div className="app">
      {!isLoading && !error ? (
        <>
          <header className="app__header margin-bottom-36">
            <img
              src={config.site.organization.logoUrl || ""}
              className="app__logo"
              alt="logo"
            />
            <h2>
              <FormattedMessage
                id="registration.header.welcome"
                defaultMessage="Welcome to {organisationName}"
                description="Registration page header welcome message"
                values={{
                  organisationName: config.site.organization.name || "",
                }}
              />
            </h2>
            <p>
              <FormattedMessage
                id="registration.header.instruction"
                defaultMessage="Before you proceed, please complete the form below."
                description="Registration page instruction message"
              />
            </p>
          </header>
          {renderContent()}
        </>
      ) : (
        <Loader type="Puff" color="#f25f4c" />
      )}
    </div>
  );
};

export default React.memo(RegistrationScreen);
